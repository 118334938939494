import { UserContent, Avatar, MyAccount, UserName, MyProfile, SignOff, Pic, UserData, AvatarSubscribe, TextLogin, IconStart, CicleStyle} from "./style";
import { useAuth0 } from "@auth0/auth0-react";
import { SubscribeButton } from "@/shared/SubscribeButton";
import { deleteCookie } from "helpers";
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
import ContentIcon from "@/shared/ContentIcon";
import { useState, useRef, useEffect } from "react"; 
export const UserAvatar = ({ suscriptionPrice = "", userAuth0, userSucription }) => {
  
  const { logout } = useAuth0();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar el menú LoginOptions
  const detailsRef = useRef(null);

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.onload = resolve;
      script.onerror = reject;
      script.src = src;
      document.head.append(script);
    });
  };

  const loadComments = async () => {
    loadScript("//cdn.viafoura.net/vf-v2.js")
      .then(() => {
        console.log("CARGO VIAFOURA");
        window.vf.session.logout();
        window.vf.context.reset();
      })
      .catch(() => console.error("ERROR EJECUTANDO VIAFOURA."));
  };

  const handlerLogout = async () => {
    handleClick('ui_interaction', 'header clarin', 'Cerrar Sesión');
    await loadComments();
    window.localStorage.removeItem("wads_userProfile");
    deleteCookie("auth0_redir");
    deleteCookie("__token_auth0");
    deleteCookie("statusSus");
    deleteCookie("suscRT");
    deleteCookie("susTemp");
    deleteCookie("isAuthenticated");
    deleteCookie("invalidAuth0");
    logout({ logoutParams: { returnTo: process.env.NEXT_PUBLIC_DOMAIN_URL } });
  };
  
  const handleOutsideClick = (event) => {
    if (
      detailsRef.current && 
      !detailsRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false); // Cierra el menú si el clic ocurre fuera
    }
  };

  const handleScroll = () => {
    setIsMenuOpen(false); // Cierra el menú usuario MyAccount si se hace scroll
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", handleOutsideClick);
      window.addEventListener("scroll", handleScroll);
    } else {
      document.removeEventListener("click", handleOutsideClick);
      window.removeEventListener("scroll", handleScroll);
    }

    // Cleanup
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMenuOpen]);
  
  return (
    <>
      <UserContent>
        {!userSucription && (
          <SubscribeButton
            title="Suscribite"
            suscriptionPrice={suscriptionPrice}
          />
        )}
        <details ref={detailsRef} open={isMenuOpen}
        onToggle={(e) => setIsMenuOpen(e.target.open)}>
          <summary>
            {userSucription ?
              <>
                <TextLogin>
                  <span className="text_user">Hola, {userAuth0.name}</span>
                  <span className="text_status">SUSCRIPTOR DIGITAL</span>
                </TextLogin>
                <IconStart>
                  <ContentIcon className="starV2" width="14px" height="14px" nameIcon='starV2'/>
                </IconStart>
                <AvatarSubscribe>
                  <img
                    height="35"
                    width="35"
                    src={userAuth0.picture}
                    className="avatar"
                    alt={userAuth0.name}
                  />
                    <div
                      className={`icon_status ${isMenuOpen ? "rotated" : ""}`}
                    >
                     <ContentIcon className="arrow_toggle" width="6px" height="6px" nameIcon='arrow_toggle'/>
                    </div>  
                </AvatarSubscribe>
              </>
              : 
              <>
                <TextLogin>
                  <span className="text_user">Hola, {userAuth0.name}</span>
                  <span className="text_status">SIN SUSCRIPCIÓN</span>
                </TextLogin>
                <Avatar>
                  <img
                    height="35"
                    width="35"
                    src={userAuth0.picture}
                    className="avatar"
                    alt={userAuth0.name}
                  />
                  <div
                    className={`icon_status ${isMenuOpen ? "rotated" : ""}`}
                  >
                     <ContentIcon className="arrow_toggle" width="6px" height="6px" nameIcon='arrow_toggle'/>
                    </div>  
                </Avatar> 
              </>
            }
          </summary>
          <MyAccount>
            <Pic />
            <li>{userAuth0.email}</li>
            <li>
              <MyProfile href="https://miperfil.clarin.com" target="_blank">
                MI PERFIL
              </MyProfile>
            </li>
            <li>
              <SignOff
                onClick={handlerLogout}
                aria-label="Cerrar Sesión"
                href="#"
              >
                CERRAR SESIÓN
              </SignOff>
            </li>
          </MyAccount>
        </details>

      </UserContent>
    </>
  );
};
