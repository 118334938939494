import { Color, ColorDark, Font, breakpoints } from "@/shared/Constants";
import styled, { css } from "styled-components";

const IconStatus = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  bottom: 3px;
  left: -20px;
  > div {
    width: 6px;
    height: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${breakpoints.darkMode} {
    [data-theme="dark"] & {
      color: ${ColorDark.grey};
    }
  }
  ${breakpoints.phone} {
    top: 12px;
    right: -14px;
    transform: translateY(50%);
    bottom: initial;
    left: initial;
  }
  &.rotated {
    transform: rotate(180deg); /* Rota la flecha hacia arriba */
    transform-origin: 50%;
  }
`;

const CicleStyle = css`
  content: "";
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  border: 3px solid ${Color.white};
  border-radius: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  ${breakpoints.phone} {
    border: 1px solid ${Color.white};
  }
  ${breakpoints.darkMode} {
    [data-theme='dark'] & {
      border-color:${ColorDark.darkGrey};
    }
  }
`;

export const UserContent = styled.div`  
  position: relative;
  display: flex;
  align-items: center;
  ${breakpoints.phone} {
    details{
      padding-right: .5rem;
    }
  }
  
  summary{ 
    cursor: pointer;
    ::-webkit-details-marker{
      display: none;
    }
    span{
      display: none;
    }
    ${breakpoints.mobileFirst} {
      align-items: center;
      color: ${Color.black};
      display: flex;
      font-size: 14px;
      white-space: nowrap;
      justify-content: center;
      height: 100%;
      span{
        display: block;
        margin-top: 2px;
        min-width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
    }
    ${breakpoints.phone} {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const UserData = styled.details`
  ${breakpoints.mobileFirst} {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #8b8b8b;
    border-radius: 4px;
    padding: 1px 9px;
  }
`;

export const TextLogin = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.25rem;
  margin-left: .75rem;
  margin-right: .5rem;
  ${breakpoints.phone} {
    margin-left: 0;
  }
  .text{
    &_user {
      ${Font.sansRegular};
    }
    &_status {
      ${Font.sansBold};
    }
  }
`;

export const Avatar = styled.picture`  
  position: relative;
  margin-left: 8px;
  display: flex;
  border: 8px solid ${Color.greySeparator};
  border-radius: 50%;
  &::before {
    ${CicleStyle}
  }
  img{
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }
  ${breakpoints.phone} {
    border: 3px solid ${Color.greySeparator};
  }
  .icon_status {
    ${IconStatus}
  }
`;

export const AvatarSubscribe = styled.picture` 
  position: relative;
  display: flex;
  border: 3px solid ${Color.white};
  border: 8px solid ${Color.yellow2};
  border-radius: 50%;
  &::before {
    ${CicleStyle}
  }
  img{
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }
  ${breakpoints.phone} {
    border: 2px solid ${Color.white};
    border: 3px solid ${Color.yellow2};
  }
  .icon_status {
    ${IconStatus}
    left: -46px;
    ${breakpoints.phone} {
      right: -14px;
    }
  }
`;

export const IconStart = styled.div`
    display: flex;
    align-items:center;
    justify-content: center;
    border-radius: 20px 0 0 20px;
    min-height: 23px;
    min-width: 30px;
    background-color: ${Color.yellow2};
    padding-top: 1px;
    margin-right: -5px;
    margin-left: .5rem;
`;

export const MyAccount = styled.ul`  
  background-color: ${Color.weatherGray};
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  top: 55px;
  z-index: 2;
  right: 11px;
    ${breakpoints.phone} {
      top: 50px;
      right: 14px;
    }
  li{
    color: ${Color.grey};
    cursor: pointer;
    ${Font.sansRegular};
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    width: 100%;
  }
`;

export const UserName = styled.summary`  
  span{
    display: none;
  }
  ${breakpoints.mobileFirst} {
    align-items: center;
    color: ${Color.white};
    display: flex;
    font-family: ${Font.sansRegular};
    font-size: 14px;
    span{
      display: block;
    }
  }
`;

export const MyProfile = styled.a`
  background-color: ${Color.white};
  color: ${Color.grey};
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
  padding: 6px;
`;

export const SignOff = styled.a`
  background-color: ${Color.grey888};
  color: ${Color.white};
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
  padding: 6px;
`;

export const Pic= styled.span`
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 8px 8px;
  border-color: transparent transparent ${Color.weatherGray} transparent;
  right: 5px;
  top: -8px;
`;
