import {
  deleteCookie,
  getCookie,
  getExpireDate,
  getUserProfile,
  setToken,
  setUserLocalStorage,
} from "helpers";
import { useEffect, useState } from "react";
import { ButtonLogin, GoogleOneTap, RememberUser, UserAvatar } from "./components";
import { useAuth0 } from "@auth0/auth0-react";

const Auth0 = ({ suscriptionPrice, isShowGoolgeOneTap }) => {
  const [userAuth0, setUserAuth0] = useState(null);
  const [userSucription, setUserSucription] = useState(false);
  const [showRemember, setShowRemember] = useState(false);
  const [showGoogleOneTap, setShowGoogleOneTap] = useState(false);
  const { isLoading, user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const tokenVF = async () => {
    if (isAuthenticated && getCookie("invalidTokenAuth0") != "true") {
      try{
        const token = await getAccessTokenSilently();
        setToken(token);
      }catch(e){
        if(e.error){
          document.cookie = `invalidTokenAuth0=true; path=/`;
        }
      }
    }else{console.log("vf: Error auth0 no Authenticated");}
  };


  const timePassed = (time) => {
    time = new Date(time);
    const MINUTES = 1 * 60 * 1000;
    if (new Date() - time > MINUTES) {
      return true;
    }
  };

  const cookieSuscription = (suscription) => {
    let expDate = getExpireDate(24);
    document.cookie = `statusSus=${suscription}; expires=${expDate.toUTCString()}; path=/`;
  };

  const statusSuscription = async () => {
    if(isAuthenticated && getCookie("invalidTokenAuth0") != "true"){
      const token = await getAccessTokenSilently();

      const api = `${process.env.NEXT_PUBLIC_AUTH0_API}/info?token=${token}&apikey=clarin`;
      setToken(token);
      await fetch(api, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if(data.error){
            document.cookie = `invalidTokenAuth0=true; path=/`;
            console.log("Error retrieving JWT from API");
            return false;
          }

          let jwt = data.jwt != null ? data.jwt : "";
          if (document.getElementById("wb_meter")) {
            document.getElementById("wb_meter").setAttribute("jwt", jwt);
          }
          let susStatus = data.user != null
            ? data?.user?.data?.subscriptions.map((sus) => {
                return sus.pId;
              })
            : "";
          let suscription = susStatus?.includes("CLARIN_C") || susStatus?.includes("CLARIN_D") || susStatus?.includes("CLA_OLE") ? "1" : '0';
          cookieSuscription(suscription);
          setUserSucription(suscription == "1" ? true : false);
          
          if (suscription == "1") {
            document.cookie = "susTemp=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
          } 
          if (getCookie("susTemp") == "true") {
            setUserSucription(true);
            cookieSuscription(1);
          }
          setUserLocalStorage(user, data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const statusSuscriptionId = async () => {
    const user = getUserProfile();
    console.log("Status",user.id)
    const api = `${process.env.NEXT_PUBLIC_AUTH0_API}/status/${user.id}`;
    await fetch(api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())
    .then((data) => {
      let suscription = data?.statusId ? data?.statusId : "0";
      cookieSuscription(suscription);
      setUserSucription(suscription == "1" ? true : false);
      
      if (suscription == "1") {
        document.cookie = "susTemp=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
      } 
      
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const handleUserProfile = async (userProfile) => {
    console.log("isAuthenticated",isAuthenticated)
    setUserAuth0(userProfile);
    if (document.getElementById("wb_meter")) {
      document
        .getElementById("wb_meter")
        .setAttribute("jwt", userProfile?.jwt);
    }
    if (getCookie("__token_auth0") === "") {
      tokenVF();
    }
    const suscRT = getCookie("suscRT");
    if ( getCookie("suscRT") != "" && userProfile.subscriptionStatus != "subscribed") {
      if (timePassed(suscRT)) {
        document.cookie = "suscRT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        await Promise.all([statusSuscriptionId(), statusSuscription()]);
      } else {
        setUserSucription(true);
        cookieSuscription(1);
      }
    }

    
    if (getCookie("statusSus") === "") {
      console.log("NO COOKIE")
      await Promise.all([statusSuscriptionId(), statusSuscription()]);
    }

    if(getCookie("susTemp") === "true") {
      setUserSucription(true);
      cookieSuscription(1);
    }

    if (getCookie("statusSus") == 1) {
      setUserSucription(true);
    }

    setShowRemember(true);
  }
  
  useEffect(() => {
    if (!isLoading) {
      deleteCookie("auth0_redir");
      const userProfile = getUserProfile();
      if (userProfile === null) {
        setUserSucription(false);
        isShowGoolgeOneTap && setShowGoogleOneTap(true);
        setShowRemember(true);
        return;
      }
      handleUserProfile(userProfile);
    }
  }, [isLoading]);

  return (
    <>
      {/* {showRemember && <RememberUser userSucription={userSucription} userAuth0={userAuth0}/>} */}
      {userAuth0 ? (
        <UserAvatar
          suscriptionPrice={suscriptionPrice}
          userAuth0={userAuth0}
          userSucription={userSucription}
        />
      ) : (
        <>
          {showGoogleOneTap && <GoogleOneTap />}
          <ButtonLogin suscriptionPrice={suscriptionPrice} />
        </>
      )}
    </>
  );
};

export default Auth0;
